import * as React from "react";
import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import Background from "../../../images/006.jpg";

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${Background})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      <Typography
        color="inherit"
        align="center"
        variant="h2"
        sx={{ zIndex: 2, mb: 10 }}
      >
        Uplift
        <br />
        Basketball & Cheerleading
      </Typography>
      {/* <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 }, zIndex: 2}}
      >
        Enjoy secret offers up to -70% off the best luxury hotels every Sunday.
      </Typography> */}
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="/register"
        sx={{ minWidth: 200, zIndex: 2, borderRadius: 8 }}
      >
        <Typography align='center'>
        Register Your Child(ren)
        </Typography>
      </Button>
      {/* <Typography variant="body2" color="inherit" sx={{ mt: 2, zIndex: 2 }}>
        Discover the experience
      </Typography> */}
    </ProductHeroLayout>
  );
}
